export const usePlatformSettings = () => {
    const {$i18n} = useNuxtApp();

    const domain  = useLocalState<string>("domain");
    const countryCode = useState<string>('countryCode')
    const platformName = useState<string>("platform");

    const setup = () => {
        const route = useRoute();
        const host = useRequestURL().host;
        const domainName = getDomainNameFromHost(host);
        const countryCode = getCountryCodeFromHost(host);

        domain.value = host;

        if (domainName === "bikeablecity") {
            setPlatformName("bikeable");
            setCountryCode("us");
        } else if (domainName === "bikeable" || domainName === "walkable") {
            setPlatformName(domainName);
            setCountryCode(countryCode)
        } else {
            if (route.query.platform === "walkable") {
                setPlatformName("walkable");
            } else {
                setPlatformName("bikeable");
            }
            if (route.query.country) {
                setCountryCode(route.query.country as string);
            } else {
                setCountryCode("ch");
            }
        }

        if  (process.server && !locale.value) {
            const acceptLanguage = useRequestHeaders(['accept-language'])['accept-language'];
            locale.value = findBrowserLocale(availableLocales(), parseAcceptLanguage(acceptLanguage ?? "de"));
        }

        $i18n.setLocale(locale.value ?? "de");

    }

    const setPlatformName = (thePlatform: string) => {
        if (platformName.value != thePlatform) {
            platformName.value = thePlatform;
        }
    };



    const setCountryCode = (theCode: string) => {
        countryCode.value = theCode;
    };

    const region = useCookie<number>("selectionRegion", {
        default: () => (0)
    });

    const setRegion = (newRegion: number) => {
        region.value = newRegion;
    }

    const locale = useCookie<string>("locale");

    const filterType = useCookie<'fame' | 'shame' | undefined>('filterType', {
        default: () => undefined
    });

    const filterFixed = useCookie<boolean | undefined>('filterFixed', {
        default: () => undefined
    });

    const filterOfficialAnswer = useCookie<boolean | undefined>('filterOfficialAnswer', {
        default: () => undefined
    });

    const selectedCategorieIds = useCookie<number[]>("selectedCategorieIds", {
        default: () => []
    });

    function getFilterParams(): any {
        var params: any = {};

        if (region.value != undefined && region.value != 0) {
            params["regionId"] = region.value;
        }

        if (filterType.value == "fame") {
            params["famed"] = true;
        } else if (filterType.value == "shame") {
            params["famed"] = false;
        }

        if (filterFixed.value != undefined && filterType.value != "fame") {
            params["fixed"] = filterFixed.value;
        }

        if (filterOfficialAnswer.value != undefined) {
            params["officialAnswer"] = filterOfficialAnswer.value;
        }
        if (
          selectedCategorieIds.value.length > 0
        ) {
            params["categoryIds"] = selectedCategorieIds.value;
        }
        console.log(params);
        return params;
    }

    const availableLocales = (): string[] => {
        if (countryCode.value === "us") {
            return ["en"];
        } else if (countryCode.value === "at") {
            return ["de"];
        } else if (countryCode.value === "de") {
            return ["de"];
        } else {
            return ["de", "fr", "it", "en"];
        }
    }

    const setLocale = (newLocale: string) => {
        if (countryCode.value === "us") {
            locale.value = "en";
        } else if (countryCode.value === "at" || countryCode.value === "de") {
            locale.value = "de";
        } else if (availableLocales().includes(newLocale)) {
            locale.value = newLocale;
        } else {
            locale.value = "de";
        }

        $i18n.setLocale(locale.value);
    };

    function getDomainNameFromHost(host: string) {
        const parts = host.split(".");
        if (parts.length === 3) {
            return parts[1];
        } else {
            return parts[0];
        }
    }

    function getCountryCodeFromHost(host: string) {
        const parts = host.split(".");
        if (parts.length === 3) {
            return parts[2];
        } else {
            return parts[1];
        }
    }


    return {
        setup,
        platformName,
        setPlatformName,
        countryCode,
        setCountryCode,
        region,
        setRegion,
        locale,
        filterType,
        filterFixed,
        filterOfficialAnswer,
        getFilterParams,
        availableLocales,
        setLocale,
        domain
    }

}
